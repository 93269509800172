/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Imports
	# Custom properties
	# Reset
	# Typography
	# Elements
	# bLOCKS
	# Media
	# Links
	# Header
	# Footer
# Accessibility

--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Imports
--------------------------------------------------------------*/
@import "_custom-properties.css";
@import "_reset.css";
@import "_typography.css";
@import "_elements.css";
@import "_links.css";
@import "_blocks.css";
@import "_header.css";
@import "_burger-menu.css";
@import "_search.css";
@import "_media.css";
@import "_footer.css";
@import "_select2.css";


/*=================================
site-main
=======================================*/
main.site-main {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

main.site-main>.css_prefix-page-inner {
    list-style-type: none;
}

main.site-main>.css_prefix-page-inner>.css_prefix-pages-items {
    width: 100%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    min-height: 1px;
    float: left;
}

/* ============OLD CSS============= */




/* Gallery */

.gallery-size-thumbnail .gallery-item {
    margin-right: 2%;
    width: 18.4%;
    margin-bottom: 2%;
    display: inline-block;
    vertical-align: top;
}

.gallery-item .gallery-caption {
    line-height: 22px;
    font-size: var(--font-size-sm);
}

.gallery-size-thumbnail .gallery-item img {
    margin-bottom: 10px;
}

.gallery-columns-1 .gallery-item {
    width: 100%;
    margin-right: 0;
}

.gallery-columns-2 .gallery-item {
    width: 48%;
}

.gallery-columns-3 .gallery-item {
    width: 31.3%;
}

.gallery-columns-4 .gallery-item {
    width: 23%;
}

.gallery-columns-5 .gallery-item {
    width: 18%;
}

.gallery-columns-6 .gallery-item {
    width: 14.6%;
}

.gallery-columns-7 .gallery-item {
    width: 12.2%;
}

.gallery-columns-8 .gallery-item {
    width: 10.5%;
}

.gallery-columns-9 .gallery-item {
    width: 9.1%;
}

.gallery.gallery-size-thumbnail {
    display: inline-block;
    width: 100%;
}


/* Audio */

article.hentry.format-audio .iq-blog-image {
    text-align: left;
}

article.hentry.format-audio .iq-blog-image p:nth-child(-n+2) {
    display: inline-block;
    margin-bottom: 0;
    padding-top: 30px;
}

article.hentry.format-audio .iq-blog-image p {
    margin-bottom: 0;
    padding-left: 30px;
}

article.hentry.format-video .iq-blog-image p {
    margin-bottom: 0;
}


/*---------------------------------------------------------------------
  Social Media
-----------------------------------------------------------------------*/

ul.info-share li {
    margin-right: 15px;
    display: inline-block;
    float: none;
    vertical-align: middle;
    text-align: center;
    list-style: none;
}

ul.info-share li a {
    display: block;
    color: var(--white-color);
    position: relative;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-decoration: none;
    background: var(--primary-color);
    font-size: var(--font-size-sm);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}

ul.info-share li a:hover {
    background: var(--secondary-color);
    color: var(--white-color);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    transform: translateY(-2px);
}


/*---------------------------------------------------------------------
  Banners
-----------------------------------------------------------------------*/

.bg-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
}

.bg-video #player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -2;
}

.bg-video .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
}

.video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*---------------------------------------------------------------------
                        Smooth  Transation effect
-----------------------------------------------------------------------*/

.transation,
i,
.iq-button-style-2 .iq-btn-icon-holder:after,
.iq-button-style-2 .iq-btn-text-holder,
.icon-box-title,
.icon-box-desc {
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
}

/*---------------------------------------------------------------------
                          Clearing floats
-----------------------------------------------------------------------*/

.home.blog .content-area .site-main {
    padding: 100px 0;
}

.site-padding {
    padding-left: 45px!important;
}

.overview-block-ptb {
    padding-top: 100px;
}

.sf-content:after,
.sf-content:before {
    clear: both;
    content: "";
    display: table;
}

.slider-overview-block-pt {
    padding-top: 70px;
}

.content-area .site-main {
    padding: 150px 0;
    display: inline-block;
    width: 100%;
    overflow: hidden;
}

.site-main .sf-content>.vc_row.wpb_row.vc_row-fluid {
    padding: 100px 0;
}

.home .content-area .site-main {
    padding: 0;
}

.home.blog .content-area .site-main {
    padding: 130px 0;
}

.no-padding,
.no-padding .vc_column-inner {
    padding: 0!important;
}

.no-margin,
.no-margin .wpb_content_element {
    margin: 0!important;
}


/*---------------------------------------------------------------------
                          Clearing floats
-----------------------------------------------------------------------*/

.clear:after,
.wrapper:after,
.format-status .entry-header:after {
    clear: both;
}

.clear:before,
.clear:after,
.wrapper:before,
.wrapper:after,
.format-status .entry-header:before,
.format-status .entry-header:after {
    display: table;
    content: "";
}


/*---------------------------------------------------------------------
 Background Gradient
---------------------------------------------------------------------*/

.iq-bg-over {
    position: relative;
}

.iq-over-dark-10:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    background: rgb(239, 241, 254, 0.1);
    display: block!important;
}

.iq-over-dark-20:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    background: rgb(239, 241, 254, 0.2);
    display: block!important;
}

.iq-over-dark-30:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    background: rgb(239, 241, 254, 0.3);
    display: block!important;
}

.iq-over-dark-40:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    background: rgb(239, 241, 254, 0.4);
    display: block!important;
}

.iq-over-dark-50:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    background: rgb(239, 241, 254, 0.5);
    display: block!important;
}

.iq-over-dark-60:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    background: rgb(239, 241, 254, 0.6);
    display: block!important;
}

.iq-over-dark-70:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    background: rgb(239, 241, 254, 0.7);
    display: block!important;
}

.iq-over-dark-80:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    background: rgb(0, 0, 0, 0.8);
    display: block!important;
}

.iq-over-dark-85:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    background: rgb(239, 241, 254, 0.85);
    display: block!important;
}

.iq-over-dark-90:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    background: rgb(239, 241, 254, 0.9);
    display: block!important;
}

.iq-over-dark-95:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    background: rgb(239, 241, 254, 0.95);
    display: block!important;
}

/*--------------------------------------------------------------
# Nice Select Dropdown
--------------------------------------------------------------*/
.nice-select.wide {
    background: var(--white-color);
    border-radius: var(--border-radius);
    border: 1px solid rgba(36, 38, 43, 0.14);
    float: none;
}

.nice-select.wide .list {
    z-index: 99;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
    -webkit-box-shadow: 0 0 87.3px 2.7px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0 0 87.3px 2.7px rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 87.3px 2.7px rgba(0, 0, 0, 0.06);
    border: 1px solid var(--primary-color);
    border-radius: var(--border-radius);
    background: var(--white-color);
}

.nice-select.wide .list::-webkit-scrollbar-thumb,
body .stm_compare_cars_footer_modal.stm-open .stm-compare-list-wrap::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: var(--border-radius);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.nice-select.wide .list::-webkit-scrollbar,
body .stm_compare_cars_footer_modal.stm-open .stm-compare-list-wrap::-webkit-scrollbar {
    width: 4px;
}

.nice-select.wide .list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: var(--border-radius);
}

.nice-select,
.nice-select.form-control {
    line-height: 50px;
    height: 50px;
    font-size: var(--font-size-body);
    padding: 0 30px 0 20px;
    background: var(--white-color);
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
    color: var(--white-color);
    background-color: var(--primary-color);
}

input.form-control {
    line-height: 54px;
    height: 54px;
}

.nice-select .list:hover .option:not(:hover) {
    color: var(--title-color);
}

.nice-select:after {
    content: "\f0dd";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    border: none;
    width: auto;
    height: auto;
    display: inline-block;
    position: absolute;
    top: 46%;
    transform: translate(0, -50%);
    margin: 0;
    right: 20px;
}

.nice-select.open:after {
  -ms-transform: translateY(-80%) rotate(180deg);
  -webkit-transform: translateY(-80%) rotate(180deg);
  transform: translateY(-80%) rotate(180deg);
  right: 23px;
}

.nice-select.wide .list li {
    padding-bottom: 5px;
    padding-top: 5px;
    margin-bottom: 0;
    white-space: normal;
    border-bottom: 1px solid rgba(82, 95, 129, 0.09);
}

.mc4wp-form-fields {
    position: relative;
}

.mc4wp-form button.btn-submit {
    position: absolute;
    right: 0;
    top: 0;
    background: var(--primary-color);
    border: 0;
    cursor: pointer;
    outline: none;
    color: var(--white-color);
    height: 100%;
    width: 54px;
    padding: 0;
    text-transform: uppercase;
    font-size: var(--font-size-sm);
    letter-spacing: var(--letter-spacing-one);
}

.mc4wp-form button.btn-submit i {
    margin-right: 5px;
}

.newsletter-main .mc4wp-form-fields input {
    border-color: #CBCBCB;
    background-color: transparent;
}

.newsletter-main .mc4wp-form-fields input:focus {
    border-color: var(--primary-color);
}

.mc4wp-form-fields:after {
    display: block;
    clear: both;
    content: "";
}

.mc4wp-form-fields input {
    padding-right: 65px;
}

/*---------------------------------------------------------------------
About style home-2
---------------------------------------------------------------------*/

.about-kivicare-box {
    overflow: hidden;
    box-shadow: 0 18px 21px 0 rgba(0, 0, 0, 0.08);
}

.about-box {
    display: block;
}

.fancy-about img {
    width: 60px;
}

.fancy-about {
    padding: 45px 45px 85px;
}

.fancy-about i:before {
    font-size: 60px;
}

.first .fancy-about {
    padding: 0 25px;
}

.fancy-about i,
.fancy-about h4,
.fancy-about h6,
.effect-box p {
    color: var(--white-color);
}

.fancy-about i {
    line-height: 80px;
    display: inline-block;
}

.fancy-about h6 {
    font-weight: 400;
    font-family: var(--global-font-family);
}

.effect-box {
    width: 40px;
    height: 40px;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 9;
    padding: 0;
    transition: all .7s;
    background: var(--secondary-color);
    text-align: left;
}

.effect-btn i {
    color: var(--white-color);
}

.effect-btn {
    font-size: var(--font-size-24);
    cursor: pointer;
    text-align: center;
    line-height: 46px;
}

.effect-btn p {
    text-align: left;
}

.first-box .effect-box.main,
.first-box .effect-box {
    background: var(--primary-color);
}

.first-box .effect-box.main .effect-btn {
    right: 28px;
}

.effect-box.main {
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 45px;
    background: var(--secondary-color);
}

.effect-box.main .effect-btn {
    text-align: right;
    position: absolute;
    top: 0;
    right: 12px;
}

.about-kivicare-box .no-margin {
    position: relative;
    overflow: hidden;
}

.about-kivicare-box img {
    width: 100%;
}


/*
* ----------------------------------------
* animation fade-in
* ----------------------------------------
*/

.fade-in {
    -webkit-animation: fade-in .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.iq-contact li.iq-week {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: 45px;
    margin-bottom: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(82, 95, 129, 0.09);
}

.iq-contact li.iq-week .iq-time {
    color: var(--secondary-color);
    font-weight: var(--font-weight-medium);
}

@media(max-width:575px) {

    .mc4wp-form input {
        padding-right: 0;
    }
}

@media(max-width:479px) {
    .iq-contact li.iq-week {
        margin: 0;
    }
}

/*---------------------------------------------------------------------
Kivicare Plugin
-----------------------------------------------------------------------*/

.book-appointment-widget .float-right .btn.kivi-btn-primary:focus,
.btn.kivi-btn-primary.btn-block:focus {
    outline: none;
    box-shadow: none;
}

.book-appointment-widget .float-right .btn.kivi-btn-primary {
    padding: 10px 18px;
    margin-top: 42px;
    border-radius: 0;
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    line-height: 24px!important;
}

.card-body .kivi-media-body p i {
    vertical-align: middle;
    color: var(--primary-color);
}

.modal-body .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background: var(--primary-color);
    border-radius: 0;
}

.modal-body .btn.kivi-btn-primary.btn-block,
.btn.kivi-btn-primary.float-right {
    padding: 10px 18px;
    border-radius: 0;
}

.form-control,
#appointmentDate,
._horizontal-scroll .card,
.modal-content,
#appointmentDataForm .badge {
    border-radius: 0;
}

.form-control:focus {
    border-color: var(--primary-color);
    box-shadow: none;
}

#kiviCarePatientRegister .row .col-md-12 {
    margin-bottom: 15px;
}

.btn.kivi-btn-primary.float-right {
    margin-bottom: 0!important;
}

button.kivi-btn-primary {
    border: 1px solid!important;
    border-color: var(--primary-color)!important;
    border-radius: 0!important;
}

.kvp-modal .form-control,
.kvp-wraper .badge-custom-kivi {
    border-radius: 0!important;
}

button.kivi-btn-primary:focus,
button.kivi-btn-primary:hover {
    border: 1px solid!important;
    border-color: var(--secondary-color)!important;
}

.card-body .kivi-text-primary {
    color: var(--title-color);
}

.card-body .kivi-details small,
.card-body .media-body p small i,
.card-body #appointmentDataForm .widget_session_slots.fadeIn.form-control .kivi-text-primary {
    color: var(--primary-color);
}

.card-body .kivi-details small {
    text-transform: uppercase;
    font-size: var(--font-size-sm);
}

.card-body .media-body p {
    font-size: var(--font-size-20);
}

.VueCarousel.kc-doctor-slider .card {
    border-radius: 0;
}

#appointmentDataForm .widget_session_slots.fadeIn.form-control {
    padding-top: 30px;
}

.card-header.kivi-header {
    border-radius: 0!important;
}

.scrollbar-thumb,
.scrollbar-track-y {
    width: 4px!important;
}

.scrollbar-thumb {
    background: var(--primary-color)!important;
}

::-webkit-scrollbar-thumb {
    background: var(--primary-color)!important;
}

@media(max-width:1024px) {
    .content-area .site-main {
        padding: 70px 0;
    }
}

@media(max-width:991px) {
    ._horizontal-scroll .d-inline-block {
        width: 45%;
    }
}

@media(max-width:767px) {
    .content-area .site-main {
        padding: 50px 0;
    }

    .kivi-doctor-name {
        display: block;
    }

    ._horizontal-scroll .media {
        display: block;
        text-align: center;
    }

    ._horizontal-scroll .media-body {
        margin: 15px 0 0;
    }

    ._horizontal-scroll .d-inline-block {
        width: 100%;
    }
}


/*---------------------------------------------------------------------
Kivicare Plugin
-----------------------------------------------------------------------*/
.kvp-wraper .card-header.kivi-header,
.kvp-wraper .kivi-calender .vc-day-content.vc-focusable.vc-font-bold.vc-text-white.vc-font-medium.vc-text-sm.vc-cursor-pointer,
.float-right .kivi-btn-primary {
    background: var(--primary-color)!important;
}

.kvp-wraper .book-appointment-widget .float-right .btn.kivi-btn-primary {
    margin-top: 20px;
}

.float-right .kivi-btn-primary,
.kvp-wraper .form-control,
.kvp-wraper #appointmentDate,
.kvp-wraper .card.book-appointment-widget {
    border-radius: 0!important;
}

.float-right button.kivi-btn-primary:focus {
    border: 1px solid!important;
    border-color: var(--secondary-color)!important;
}

.iq-btn-container .iq-button.iq-btn-link {
    display: inline-block;
    text-transform: uppercase;
    font-size: var(--font-size-sm);
    letter-spacing: .08em;
    font-weight: var(--font-weight-medium);
}

.iq-btn-container .iq-button.iq-btn-link i {
    margin-left: 5px;
}

/**********button link new style************/

.iq-btn-container .iq-button.iq-btn-link:hover i {
    color: var(--secondary-color);
}

.iq-btn-container .iq-btn-link-new .btn-link-icon {
    background: var(--primary-color);
    color: var(--white-color);
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    margin-left: 5px;
}

.iq-btn-container .iq-btn-link-new .btn-link-icon i {
    color: var(--white-color);
    margin-left: 0;
    letter-spacing: 0;
}

.iq-btn-container .iq-btn-link-new:hover .btn-link-icon i {
    transform: rotate(-45deg);
    color: var(--white-color);
}

.iq-btn-container .iq-btn-link-new:hover,
.iq-btn-container .iq-btn-link-new:focus {
    color: var(--primary-color);
}

.iq-btn-container .iq-btn-link-new {
    letter-spacing: .063em;
}

/**********button style new************/

.iq-new-btn-style .iq-btn-text-holder,
.iq-reply.iq-button-style-2.iq-new-btn-style .iq-btn-text-holder,
.iq-new-btn-style .iq-button-style-2 .iq-btn-text-holder,
.iq-new-btn-style.iq-button-style-2 .iq-btn-text-holder,
.iq-new-btn-style.iq-comment-btn .iq-btn-text-holder {
    padding: 0 15px 0 0;
    position: relative;
    background: transparent;
    margin-right: 15px;
    font-weight: var(--font-weight-body);
    line-height: 24.5px;
    font-size: var(--font-size-sm);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-one);
}

.iq-new-btn-style.iq-button-style-2 span.iq-btn-text-holder {
    background: transparent!important;
}

.iq-new-btn-style .iq-btn-text-holder:after {
    content: '';
    position: absolute;
    top: 50%;
    right: -1px;
    transform: translateY(-50%);
    height: 22px;
    width: 1px;
    background-color: var(--border-color-light);
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
}

.iq-new-btn-style .iq-btn-icon-holder:after,
.iq-new-btn-style.iq-button-style-2 .iq-btn-icon-holder:after {
    content: none;
}

.iq-new-btn-style .iq-btn-icon-holder,
.iq-new-btn-style.iq-button-style-2 .iq-btn-icon-holder,
.iq-reply.iq-button-style-2.iq-new-btn-style .iq-btn-icon-holder,
.iq-new-btn-style .iq-button-style-2 .iq-btn-icon-holder,
.iq-new-btn-style.iq-comment-btn .iq-btn-icon-holder {
    padding: 0;
}

.iq-new-btn-style button:hover i,
.iq-new-btn-style a:hover i,
.swal2-styled:hover .iq-btn-icon-holder i,
.iq-new-btn-style:hover .iq-btn-icon-holder i {
    transform: rotate(-45deg);
}

.iq-new-btn-style,
.iq-new-btn-style a,
.iq-new-btn-style button,
.commentlist .iq-comments-media .reply a {
    padding: 12px 24px;
    background-color: var(--secondary-color);
    box-shadow: none;
    border: none;
}

.iq-comment-btn.iq-new-btn-style:hover .iq-btn-text-holder,
.iq-new-btn-style .iq-button-style-2:hover .iq-btn-text-holder,
.iq-new-btn-style .iq-button-style-2:hover .iq-btn-icon-holder:after,
.iq-new-btn-style.iq-button-style-2:hover .iq-btn-text-holder,
.iq-new-btn-style.iq-button-style-2:hover .iq-btn-icon-holder:after,
.iq-comment-btn.iq-new-btn-style:hover .iq-btn-icon-holder:after {
    background: transparent;
}

.woocommerce-mini-cart__buttons .iq-new-btn-style.wc-forward {
    padding: 6px 12px;
    font-size: var(--font-size-sm);
}

.woocommerce-mini-cart__buttons .iq-new-btn-style.wc-forward.checkout {
    background-color: var(--primary-color);
}

/*Chart */

.iq-chart-data {
    margin-top: -100px!important;
}

.iq-chart-data canvas.vc_round-chart-canvas {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)!important;
    width: auto!important;
    height: 300px!important;
    padding: 45px;
    border-radius: 20px;
    background: var(--white-color);
}

.iq-chart-data canvas.vc_line-chart-canvas {
    padding: 15px 30px;
    width: 600px!important;
    height: 300px!important;
    border-radius: 20px;
    background: var(--white-color);
}

.iq-chart-data .vc_chart .vc_chart-with-legend {
    width: 70%;
}

.iq-chart-data .vc_chart .vc_chart-legend {
    width: 30%;
}

.iq-chart-data .vc_chart.vc_line-chart.wpb_content_element {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)!important;
    border-radius: 20px;
}


/*About Section*/

.iq-about-edu {
    padding: 0;
    margin: 0;
    display: inline-block;
    width: 100%;
}

.iq-about-edu li {
    margin-bottom: 15px;
    list-style-type: none;
    width: 100%;
    display: inline-block;
}

.iq-about-edu li a {
    color: var(--body-text);
    position: relative;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    width: 100%;
    display: inline-block;
    float: left;
}

.iq-about-edu li a i {
    position: absolute;
    left: 0;
    line-height: 34px;
    font-size: var(--font-size-18);
    color: var(--secondary-color);
}

.iq-about-edu li a .header {
    width: 100px;
    color: var(--primary-color);
    float: left;
    font-size: var(--font-size-body);
    line-height: 26px;
}

.iq-about-edu li a .content {
    display: table-cell;
    word-wrap: anywhere;
    word-break: break-word;
}

.iq-higher-edu img {
    max-width: 150%!important;
    top: -20%;
    position: absolute;
    left: -62%;
}

.video-edu .consult-video a {
    width: 50px;
    height: 50px;
    line-height: 50px;
}

.video-edu .video-btn .btn-waves {
    display: none;
}

.video-edu .main-video {
    left: 10%;
}

.iq-grey-bg .owl-carousel.client-logo.client-bg-shadow.owl-loaded.owl-drag {
    background: var(--white-light-color)!important;
}


.woo-menu {
    display: inline-block;
    position: relative;
    width: 60px;
}

#shop-toggle .kivi-res-shop-btn-container {
    font-size: 20px;
    display: inline-block;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    width: 46px;
    height: 46px;
    text-align: center;
    color: var(--white-color);
    background: var(--primary-color);
    outline: none;
    z-index: 100000;
    -webkit-transition: all .3s;
    transition: all .3s;
    border-radius: 0;
}

#shop-toggle .kivi-res-shop-btn-container a {
    color: var(--white-color);
}

#shop-toggle {
    -webkit-perspective: 1000px;
    perspective: 1000px;
    z-index: 9;
    right: 30px;
    position: absolute;
    top: -27px;
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
}

#shop-toggle:hover .iq-shop-btn {
    visibility: visible;
}

#shop-toggle {
    right: 0;
}

#shop-toggle .kivi-res-shop-btn-container:hover .iq-shop-btn {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

#shop-toggle .iq-shop-btn {
    font-size: 16px;
    position: absolute;
    margin: 0;
    top: 50px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition-delay: .5s;
    transition-delay: .5s;
}

#shop-toggle ul.shop_list {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: -1;
}

#shop-toggle ul.shop_list li {
    position: relative;
    opacity: 0;
    width: 45px;
    height: 45px;
    -webkit-transition: all .5s;
    transition: all .5s;
    margin: 0;
}

#shop-toggle ul.shop_list li a {
    border-radius: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #000;
    text-decoration: none;
    text-align: center;
    font-size: 16px;
    width: 46px;
    height: 46px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    background: var(--white-color);
    text-decoration: none;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.05);
    padding: 0;
}

#shop-toggle ul.shop_list li a:hover {
    background: var(--primary-color);
    color: var(--white-color);
}

#shop-toggle ul.shop_list li a:hover i {
    color: var(--white-color);
}

#shop-toggle input:checked~.kivi-res-shop-btn-container {
    background: var(--white-color);
    color: var(--primary-color);
}

#shop-toggle input:checked~.iq-shop-btn {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

#shop-toggle .shop_list.hover li {
    opacity: 1;
    -webkit-transition: all .3s;
    transition: all .3s;
    z-index: 4;
}

#shop-toggle .shop_list.hover li:nth-of-type(1) {
    -webkit-transition-delay: 0;
    transition-delay: 0;
    z-index: 4;
}

#shop-toggle .shop_list.hover li:nth-of-type(2) {
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
    z-index: 3;
}

#shop-toggle .shop_list.hover li:nth-of-type(3) {
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
    z-index: 2;
}

#shop-toggle .shop_list.hover li:nth-of-type(4) {
    -webkit-transition-delay: .4s;
    transition-delay: .4s;
    z-index: 1;
}

#shop-toggle:hover ul.shop_list li {
    opacity: 1;
    -webkit-transition: all .3s;
    transition: all .3s;
    z-index: 4;
}

#shop-toggle input:checked~ul.shop_list li {
    opacity: 1;
    -webkit-transform: translateY(0) rotate(360deg);
    transform: translateY(0) rotate(360deg);
    -webkit-transition: all .3s;
    transition: all .3s;
}

#shop-toggle:hover ul.shop_list li:nth-of-type(1) {
    -webkit-transition-delay: 0;
    transition-delay: 0;
    z-index: 4;
}

#shop-toggle:hover ul.shop_list li:nth-of-type(2) {
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
    z-index: 3;
}

#shop-toggle:hover ul.shop_list li:nth-of-type(3) {
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
    z-index: 2;
}

#shop-toggle:hover ul.shop_list li:nth-of-type(4) {
    -webkit-transition-delay: .4s;
    transition-delay: .4s;
    z-index: 1;
}

#shop-toggle ul.shop_list li {
    display: block;
    margin-top: 5px;
}

#shop-toggle .wishlist_count .wcount {
    position: absolute;
    top: 1px;
    right: -10px;
}

#shop-toggle ul.shop_list li.wishlist-btn {
    padding: 0;
}

#shop-toggle ul.shop_list li.cart-btn {
    padding: 0;
}


header .navbar .sub-main ul.shop_list li .wcount,
header .navbar .sub-main ul.shop_list li .cart_count #mini-cart-count {
    position: absolute;
    top: 0;
    right: -8px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: var(--white-color);
    font-size: 12px;
    background: var(--primary-color);
    border-radius: 90px;
}

/*********Minicart**********/

@media(max-width:1399px) {
    .owl-carousel .owl-nav .owl-prev {
        left: -50px;
    }

    .owl-carousel .owl-nav .owl-next {
        right: -50px;
    }
}

@media(max-width:1365px) {
    .owl-carousel .owl-nav .owl-next {
        right: -10px;
    }

    .owl-carousel .owl-nav .owl-prev {
        left: -10px;
    }
}


@media only screen and (device-width:1024px) {
    .owl-carousel.client-logo.client-bg-shadow.owl-loaded.owl-drag {
        margin-top: -160px;
    }
}

@media(max-width:1024px) {
    .owl-carousel.client-logo.client-bg-shadow.owl-loaded.owl-drag {
        margin-top: -160px;
    }
}


/* ==== only responsive css ==== */


@media(max-width:1299px) {
    body.vertical-menu-expanded .site.vertical-site-content {
        padding: 0;
    }

    .iq-vertical-btn-container.btn-vertical-open,
    .vertical-navbar-brand {
        display: inline-block;
    }

    body.vertical-menu-expanded .iq-vertical-btn-container.btn-vertical-open,
    body.vertical-menu-expanded .vertical-navbar-brand {
        display: inline-block;
        opacity: 1;
    }

    body.vertical-menu-close header.style-vertical {
        left: 0;
        z-index: 999;
    }

    body.vertical-menu-expanded .site.vertical-site-content .elementor-section.elementor-section-stretched {
        padding-left: 0!important;
    }

}

@media(max-width:479px) {
    .iq-coming h1 {
        font-size: var(--font-size-42);
        margin-bottom: 30px;
    }

    .iq-coming .countdown li {
        width: auto;
        height: auto;
        padding: 10px;
        margin: 0;
        border: none;
    }

    .iq-coming .countdown li span:after {
        top: -9px;
        right: -20px;
        font-size: var(--font-size-24);
    }

    .iq-coming .countdown li span {
        font-size: var(--font-size-24);
        line-height: 24px;
    }

    .iq-coming .countdown li p {
        font-size: var(--font-size-xs);
        line-height: 20px;
        font-weight: inherit;
    }

    .year-info.fly-text {
        left: 0;
    }

    .year-info span {
        font-size: 80px;
    }

    .year-box:before {
        right: -15px;
        top: 15px;
        border-width: 15px 0 0 15px;
    }

    .year-box {
        width: 140px;
        height: 120px;
    }

    .year-box span {
        font-size: var(--font-size-36);
    }

    .maintenance h2 {
        font-size: var(--font-size-32);
    }

    .client-border .owl-carousel .owl-item img {
        margin: 0 auto;
    }

    .about-us-images .main-video {
        position: relative;
        top: 0;
    }

    .careers-faq .iq-accordion .iq-ad-block .ad-title span {
        font-size: var(--font-size-20);
    }
}

@media(max-width:527px) {
    #vertical-menu-sub-main {
        display: none;
    }
}

@media(max-width:1199px) {
    .elementor-image-box-wrapper .elementor-image-box-description br {
        display: none;
    }

    #shop-toggle {
        right: 60px;
    }
}






.maintenance {
	text-align: center;
	padding: 290px 0;
}

.maintenance {
		padding: 150px 0;
	}

.maintenance h2 {
		font-size: 40px;
	}

.maintenance h2 {
		font-size: 32px;
	}


	/*---------------------------------------------------------------------
Coming soon
-----------------------------------------------------------------------*/
.maintenance {
	text-align: center;
	padding: 290px 0;
}

.iq-coming {
	padding: 143px 0;
	text-align: center;
}

.iq-coming h1 {
	font-size: 80px;
}

.iq-coming ul {
	list-style: none;
}

.iq-coming .countdown {
	list-style: none;
	margin: 20px 0 30px 0;
	padding: 0;
	text-align: center;
	display: inline-block;
}

.iq-coming .countdown li {
	display: inline-block;
	margin: 0 20px;
	border: 1px solid rgba(0, 0, 0, 0.13);
	width: 140px;
	height: 150px;
	padding: 14px;
}

.iq-coming .countdown li span {
	font-size: 50px;
	font-weight: bold;
.iq-coming .countdown li h6 {
	color: var(--secondary-color);
	font-size: 20px;
	padding-right: 30px;
}

.iq-coming .countdown li p {
	font-size: 16px;
	line-height: 24px;
	color: var(--secondary-color);
}

.iq-coming .countdown li span:after {
	position: absolute;
	right: -69px;
	top: 30px;
	content: ":";
	font-size: 50px;
	color: var(--secondary-color);
	line-height: 50px;
}

.iq-coming .countdown li:last-child span:after {
	content: none;
}	line-height: 80px;
	color: var(--secondary-color);
	position: relative;
}

.iq-coming .countdown li.seperator {
	font-size: 50px;
	line-height: 40px;
	vertical-align: top;
	margin: 16px 0px;
	color: var(--secondary-color);
}

.iq-coming .countdown li h6 {
	color: var(--secondary-color);
	font-size: 20px;
	padding-right: 30px;
}

.iq-coming .countdown li p {
	font-size: 16px;
	line-height: 24px;
	color: var(--secondary-color);
}

.iq-coming .countdown li span:after {
	position: absolute;
	right: -69px;
	top: 30px;
	content: ":";
	font-size: 50px;
	color: var(--secondary-color);
	line-height: 50px;
}

.iq-coming .countdown li:last-child span:after {
	content: none;
}
	
.css_prefix-service-style3 .css_prefix-service-box-icon img, .css_prefix-service-style3 .css_prefix-service-box-icon svg{
    height: 70px;
    width: 70px;
}