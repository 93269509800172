body.admin-bar header.header-up,
body.admin-bar header.header-down {
	position: fixed;
	left: 0;
	right: 0;
	z-index: 99;
	transition: all .45s ease 0s;
	-webkit-transition: all .45s ease 0s;
	-moz-transition: all .45s ease 0s;
	-o-transition: all .45s ease 0s;
}

.admin-bar header.menu-sticky .main-header {
	top: 0;
	padding: 0 162px;
}

.admin-bar header.menu-sticky .container.main-header.main-header {
	padding: 0 15px;
	box-shadow: none;
}

.admin-bar header.menu-sticky .main-header {
	padding: 0 40px;
}

@media(min-width:2200px) {
	.admin-bar header.menu-sticky .main-header {
		padding: 0 40px;
	}
}

@media(max-width:1920px) {
	.admin-bar header.menu-sticky .main-header {
		padding: 0 40px;
	}
}

@media(max-width:1399px) {
	.admin-bar header.menu-sticky .main-header {
		padding: 0 45px;
	}
}

@media(max-width:1365px) {
	.admin-bar header.two.menu-sticky .main-header {
		padding: 0 15px;
	}
}


@media(max-width:1199px) {
	.admin-bar header.menu-sticky .main-header {
		padding: 0 15px;
	}

	.admin-bar header.menu-sticky .main-header {
		padding: 10px 15px;
	}
}

@media only screen and (device-width:1024px) {
	.admin-bar header.menu-sticky .main-header {
		padding: 0 15px;
	}
}

@media(max-width:1024px) {
	.admin-bar header.menu-sticky .main-header {
		padding: 10px 15px;
	}
}

@media(max-width:767px) {
	.admin-bar header {
		transition: all 0s ease-in-out;
		-moz-transition: all 0s ease-in-out;
		-ms-transition: all 0s ease-in-out;
		-o-transition: all 0s ease-in-out;
		-webkit-transition: all 0s ease-in-out;
	}

	.admin-bar header.menu-sticky .main-header {
		top: 0;
	}

	.admin-bar header.menu-sticky {
		top: 0;
	}

	header.menu-sticky .main-header {
		position: relative;
	}
}

@media only screen and (min-device-width:601px) and (max-device-width:768px) {
	.admin-bar header.menu-sticky .main-header {
		top: 46px;
	}
}


/* Custome header */
body.admin-bar header,
body.admin-bar.css_prefix-default-header header {
	top: 2em;
}

body.admin-bar .has-sticky.header-up {
	top: 2em;
}

@media (max-width:600px) {

	/* Static adminbar (Not sticky)*/
	body.admin-bar .has-sticky.header-up {
		top: 0;
	}

	body.admin-bar header {
		top: 2.875em;
	}
}

@media only screen and (max-width: 601px) {

	body.admin-bar header.no-header-layout,
	body.admin-bar.css_prefix-default-header header {
		top: 2.875em;
	}

	body.admin-bar header.no-header-layout.menu-sticky,
	body.admin-bar.css_prefix-default-header header.menu-sticky {
		top: 0;
	}
}

@media only screen and (min-width: 601px) and (max-width: 782px) {

	body.admin-bar .has-sticky.header-up,
	body.admin-bar header,
	body.admin-bar.css_prefix-default-header header {
		top: 2.875em;
	}
}