@import "_adminbar.css";

/* ==========old Css===== */

/*---------------------------------------------------------------------
                           Header
-----------------------------------------------------------------------*/

header.default-header {
    position: relative;
    width: 100%;
    background: var(--white-color);
    display: inline-block;
    z-index: 999;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
}

header.default-header .navbar .navbar-nav li:last-child .sub-menu li:hover>.sub-menu,
header.default-header .navbar .navbar-nav li:nth-last-child(2) .sub-menu li:hover>.sub-menu {
    left: auto;
    right: 100%;
}


/* Header Top */

header.default-header .sub-header .number-info ul {
    margin: 0;
    padding: 0;
}

header.default-header .sub-header .number-info li {
    list-style: none;
    float: left;
    margin-right: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.18);
}

header.default-header .sub-header .number-info li:last-child {
    border-right: 1px solid rgba(255, 255, 255, 0.18);
    margin-right: 0;
}

header.default-header .sub-header .number-info li a {
    color: var(--white-color);
    line-height: 45px;
    padding: 0 15px;
}

header.default-header .sub-header .number-info li a:hover {
    color: var(--primary-color);
    text-decoration: none;
}

header.default-header .sub-header .number-info li a i {
    margin-right: 5px;
}

header.default-header .sub-header nav {
    display: flex;
}

header.default-header .sub-header .request-btn a {
    background: var(--primary-color);
    padding: 14px 30px;
    color: var(--white-color);
    line-height: 45px;
    position: relative;
    border-right: 1px solid rgba(255, 255, 255, 0.30);
    z-index: 2;
}

header.default-header .sub-header .request-btn a:before {
    pointer-events: none;
    background: var(--primary-color);
    width: 100%;
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    content: "";
    display: inline-block;
    z-index: -1;
}

header.default-header .sub-header .request-btn a:hover {
    background: var(--primary-color);
    color: var(--white-color);
    text-decoration: none;
}

header.default-header .sub-header .social-icone ul {
    margin: 0;
    padding: 0;
}

header.default-header .sub-header .social-icone ul li i {
    color: var(--white-color);
    width: 45px;
    height: 45px;
    line-height: 45px;
}

header.default-header .sub-header .social-icone ul li {
    display: inline-block!important;
    text-align: center;
    border-right: 1px solid rgba(255, 255, 255, 0.30);
    height: 45px;
    background: var(--primary-color);
}

header.default-header .sub-header .social-icone ul li:last-child {
    margin-right: 0;
}

header.default-header .sub-header .social-icone ul li a {
    color: var(--secondary-color);
}

header.default-header .sub-header .social-icone ul li a:hover {
    color: var(--primary-color);
}

header.default-header .navbar-light .navbar-brand img.logo {
    width: 150px;
}

header.default-header .navbar-brand,
header.default-header .sub-main {
    padding: 16px 0;
}

header.default-header .sub-main{
    margin-left: 75px;
}

/* Mobile toggle button */

header.default-header .menu-btn .line {
    width: 25px;
    height: 2px;
    background-color: var(--white-color);
    display: block;
    margin: 7px auto;
}

header.default-header .menu-btn {
    vertical-align: middle;
}

header.default-header .menu-btn:hover {
    cursor: pointer;
}

header.default-header .menu-btn:hover {
    cursor: pointer;
}

header.default-header .menu-btn:hover {
    cursor: pointer;
}

header.default-header #menu-btn.is-active {
    -webkit-transition-delay: .6s;
    -o-transition-delay: .6s;
    transition-delay: .6s;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

header.default-header #menu-btn.is-active .line:nth-child(2) {
    width: 0;
}

header.default-header #menu-btn.is-active .line:nth-child(1),
header.default-header #menu-btn.is-active .line:nth-child(3) {
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
}

header.default-header #menu-btn.is-active .line:nth-child(1) {
    -webkit-transform: translateY(9px);
    -ms-transform: translateY(9px);
    -o-transform: translateY(9px);
    transform: translateY(9px);
}

header.default-header #menu-btn.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-9px) rotate(90deg);
    -ms-transform: translateY(-9px) rotate(90deg);
    -o-transform: translateY(-9px) rotate(90deg);
    transform: translateY(-9px) rotate(90deg);
}

header.default-header li .search-box input[type="search"] {
    height: 40px;
    font-size: var(--font-size-18);
    display: inline-block;
    border: none;
    border-radius: 0;
    outline: none;
    padding: 15px 40px 15px 15px;
    width: 0;
    position: absolute;
    top: -14px;
    right: 0;
    background: none;
    transition: width .4s cubic-bezier(0, 0.795, 0, 1);
    cursor: pointer;
    z-index: 11;
    margin: -10px 0 0;
}

header.default-header li .search-box {
    position: relative;
}

header.default-header li .search-box input[type="search"]:focus {
    width: 250px;
    cursor: text;
    background: var(--white-color);
    z-index: 9;
    color: rgba(102, 102, 102, 0.6);
    font-size: var(--font-size-sm);
}

header.default-header li .search-box .search-submit:hover {
    background: none;
}

header.default-header li .search-box .search-submit {
    z-index: 10;
    color: var(--secondary-color);
    padding: 0;
    height: 40px;
    width: 40px;
    display: inline-block;
    float: right;
    position: absolute;
    top: -24px;
    right: 0;
    background: none;
    cursor: pointer;
    transition: opacity .4s ease;
}

header.default-header li .search-box .search-submit i {
    display: inline-block;
}

/*---------------------------------------------------------------------
                           Header-style-1
-----------------------------------------------------------------------*/

header.default-header li .search-box .search-submit,
header.default-header li .search-box input[type="search"] {
    right: -30px;
}

/* =====Responsive CSS========= */

@media(max-width:1199px) {
    header.default-header li .search-box .search-submit {
        top: -46px;
        right: -6px;
    }

    header.default-header li .search-box .search-submit i {
        color: var(--secondary-color);
    }

    header.default-header li .search-box input[type="search"] {
        right: 0;
        top: -30px;
    }
}

@media(max-width:1299px) {

    header.default-header li .search-box .search-submit,
    header.default-header li .search-box input[type="search"] {
        right: -10px;
    }
}

@media(max-width:359px) {
    header.default-header li .search-box input[type="search"] {
        right: -8px;
    }

    header.default-header li .search-box input[type="search"]:focus {
        width: 235px;
    }

    header.default-header .search-box {
        display: none
    }
}

/* Header sticky */

header.default-header.menu-sticky {
    background: var(--white-color);
    position: fixed;
}

header.default-header .main-header {
    padding: 0 162px;
}

header.default-header .sub-header {
    padding: 0 162px;
    background: var(--secondary-color);
    overflow: hidden;
}

header.default-header .navbar-light {
    padding: 0;
}


/* Header.default-header Button */
header.default-header .iq-button-style-2 .iq-btn-icon-holder,
header.default-header .iq-button-style-2 span.iq-btn-text-holder,
header.default-header .iq-button-style-1 .iq-btn-icon-holder,
header.default-header .iq-button-style-1 span.iq-btn-text-holder {
    padding: 8px 15px;
    font-size: var(--font-size-sm);
}

header.default-header .iq-button-style-2 span.iq-btn-text-holder,
header.default-header .iq-button-style-2 .iq-btn-icon-holder:after {
    background: var(--primary-color);
}

header.default-header .iq-button-style-1 span.iq-btn-text-holder,
header.default-header .iq-button-style-1 .iq-btn-icon-holder:after {
    background: var(--secondary-color);
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
}

header.default-header .iq-button-style-1:hover span.iq-btn-text-holder,
header.default-header .iq-button-style-1:hover .iq-btn-icon-holder:after {
    background: var(--title-color);
}

header.default-header .iq-mobile-main {
    display: none;
}

header.default-header .navbar ul li .sub-menu li a:hover:before,
header.default-header .navbar ul li .sub-menu li.current-menu-item a:before,
header.default-header .main-header .navbar ul li .sub-menu li:hover>a:before,
header.default-header .main-header .navbar ul li .sub-menu li.current-menu-parent a:before,
header.default-header .main-header .navbar ul li .sub-menu li.current-menu-parent .sub-menu li.current-menu-item a:before {
    width: 5px;
    background: var(--white-color);
}

header.default-header.menu-sticky .navbar #iq-menu-container ul li a::before {
    background: var(--white-color);
}

header.default-header .navbar ul li .sub-menu li.current-menu-item a,
header.default-header .main-header .navbar ul li .sub-menu li:hover>a,
header.default-header .main-header .navbar ul li .sub-menu li.current-menu-parent a,
header.default-header .main-header .navbar ul li .sub-menu li.current-menu-parent .sub-menu li.current-menu-item a,
header.default-header .main-header .navbar ul li .sub-menu li.current-menu-parent .sub-menu li a:hover,
header.default-header .main-header .navbar ul li .sub-menu li.current-menu-parent>.sub-menu li a:hover {
    color: var(--white-color);
    background: var(--primary-color);
}


/*--------------------------------------------------------------
                       Header Conatiner
--------------------------------------------------------------*/
header.default-header .container.main-header {
    padding: 0 15px;
    box-shadow: none;
}

header.default-header.menu-sticky {
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}

header.default-header .container.sub-header {
    padding: 0 15px;
}


/*--------------------------------------------------------------
                       Navigation
-------------------------------------------------------------*/
header.default-header.menu-sticky .main-header {
    top: 0;
    background: var(--white-color);
}

header.default-header .navbar .menu-header-menu-container,
header.default-header .navbar .menu-all-pages-container,
header.default-header .navbar .menu-testing-menu-container,
header.default-header .navbar .menu-short-container,
header.default-header .navbar .menu-main-menu-container,
header.default-header .navbar #iq-menu-container {
    display: inline-block;
    width: 100%;
}

header.default-header .navbar ul {
    float: right;
    display: block;
    text-align: center;
}

header.default-header .navbar ul.sub-menu li {
    display: block;
    text-align: left;
}

header.default-header .navbar ul li {
    list-style: none;
    margin-right: 15px;
    position: relative;
    transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    display: inline-block;
}


header ul.shop_list {
    margin-bottom: 0;
}

header.default-header .navbar ul li:last-child {
    margin-right: 0;
}

header.default-header .navbar ul li a {
    position: relative;
    color: var(--title-color);
    padding: 0 0 0 15px;
    line-height: 80px;
    z-index: 9;
    text-decoration: none;
    transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    text-transform: capitalize;
    left: 0;
    top: 0;
}

header.default-header .navbar ul li i {
    cursor: pointer;
    display: inline-block;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-body);
    color: var(--title-color);
    transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

header.default-header .navbar ul li .sub-menu li i {
    display: block;
    position: absolute;
    right: 30px;
    font-size: var(--font-size-xs);
    z-index: 9;
    color: var(--body-text);
    transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    top: 50%;
    transform: translate(0, -50%);
}

header.default-header .navbar ul li .sub-menu li:hover>a {
    background: var(--primary-color);
    color: var(--white-color);
}

header.default-header .navbar ul li .sub-menu li:hover>i {
    color: var(--white-color);
}

header.default-header .navbar ul li .sub-menu li:hover,
header.default-header .navbar ul li a:hover,
header.default-header .navbar ul li.current-menu-item a,
header.default-header .navbar ul li.current-menu-parent a,
header.default-header .navbar ul li.current-menu-parent i,
header.default-header .navbar ul li.current-menu-item i {
    color: var(--primary-color);
}

header.default-header .menu-sticky .container-fluid.sub-header {
    display: none;
}


/* header button */
header.default-header .navbar .sub-main .blue-btn.button a {
    font-size: var(--font-size-sm);
}

header.default-header .navbar .sub-main .blue-btn.button {
    padding: 8px 20px;
}


/* Navigation Sub Menu */
header.default-header .navbar ul li .sub-menu {
    display: none;
}

header.default-header .navbar ul li:hover .sub-menu {
    background: var(--white-color);
    position: absolute;
    top: 80px;
    left: 0;
    padding: 15px 0;
    display: inline-block;
    min-width: 256px;
    -webkit-box-shadow: 0 1px 30px 0 rgba(36, 38, 43, 0.10);
    -moz-box-shadow: 0 1px 30px 0 rgba(36, 38, 43, 0.10);
    box-shadow: 0 1px 30px 0 rgba(36, 38, 43, 0.10);
}

header.default-header .navbar ul li .sub-menu li {
    margin-right: 0;
    padding: 0 15px;
}

header.default-header .navbar ul li .sub-menu li a {
    font-size: var(--font-size-sm);
    border-radius: 0;
    display: inline-block;
    width: 100%;
    line-height: 1em;
    padding: 15px;
    color: var(--body-text);
    background: var(--white-color);
}

header.default-header .navbar ul li .sub-menu li a:hover {
    background: var(--primary-color);
    color: var(--white-color);
}

header.default-header .navbar ul li .sub-menu li.current-menu-item a {
    background: var(--primary-color);
    color: var(--white-color);
}

header.default-header .navbar ul li .sub-menu li.current-menu-item ul li a {
    background: transparent;
    color: var(--primary-color);
}

header.default-header .navbar ul li.current-menu-ancestor a,
header.default-header .navbar ul li.current-menu-ancestor i {
    color: var(--primary-color);
}

header.default-header .navbar ul li .sub-menu li.current-menu-item ul li a:hover {
    color: var(--white-color);
}


/* main*/
header.default-header .navbar ul li .sub-menu li.current-menu-parent i {
    color: var(--white-color);
}

header.default-header .navbar ul li .sub-menu li.current-menu-parent a,
header.default-header .navbar ul li .sub-menu li .sub-menu li.menu-item.current-menu-item a,
header.default-header .navbar ul li .sub-menu li .sub-menu li.menu-item:hover a {
    background: var(--primary-color);
    color: var(--white-color);
}

header.default-header .navbar ul li .sub-menu li .sub-menu li.menu-item a {
    background: var(--white-color);
    color: var(--body-text);
}

@media screen and (max-width:4000px) and (min-width:1200px) {
    header.default-header .navbar ul li:hover>.sub-menu {
        display: block!important;
        -webkit-animation: fade-in .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: fade-in .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }

    header.default-header .navbar ul li:hover a,
    header.default-header .navbar ul li:hover i {
        color: var(--primary-color);
        text-decoration: none;
    }
}


/* Navigation Sub Menu */
header.default-header .navbar ul li .sub-menu li>.sub-menu {
    display: none;
}

header.default-header .navbar ul li .sub-menu li:hover>.sub-menu {
    background: var(--white-color);
    position: absolute;
    top: -15px;
    left: -100%;
    display: inline-block;
    min-width: 256px;
    -webkit-box-shadow: 0 1px 16px 0 rgba(36, 38, 43, 0.08);
    -moz-box-shadow: 0 1px 16px 0 rgba(36, 38, 43, 0.08);
    box-shadow: 0 1px 16px 0 rgba(36, 38, 43, 0.08);
}


/* Navigation Sticky */

header.default-header.menu-sticky .main-header {
    top: 0;
    background: var(--white-color);
}

header.default-header.menu-sticky .main-header .navbar ul li a {
    line-height: 80px;
    position: relative;
}

header.default-header.menu-sticky .main-header .navbar ul li:hover .sub-menu {
    top: 65px;
}

header.default-header.menu-sticky .navbar ul li .sub-menu li:hover>.sub-menu {
    top: -15px;
}

header.default-header.menu-sticky .navbar ul li .sub-menu>li a {
    line-height: 1em;
}

header.default-header.menu-sticky .navbar-light .navbar-brand img.logo {
    width: 150px;
}


/*---------------------------------------------------------------------
                           Header-style-1
-----------------------------------------------------------------------*/

header.default-header .sub-header {
    background: transparent;
}

header.default-header {
    position: absolute;
    background: transparent;
}

header.default-header .sub-header {
    font-size: var(--font-size-sm);
}

header.default-header .sub-header {
    padding: 0 162px;
}



header.default-header .sub-header .number-info li a {
    font-size: var(--font-size-sm);
    margin-right: 0;
}

header.default-header .sub-header .number-info li a {
    color: var(--body-text);
    line-height: 45px;
    padding: 0;
    margin-right: 30px;
}

header.default-header .sub-header .social-icone ul li i,
header.default-header .sub-header .number-info li a i {
    color: var(--secondary-color);
}

header.default-header .sub-header .social-icone ul li:hover i,
header.default-header .sub-header .number-info li:hover a i,
header.default-header .sub-header .number-info li:hover a {
    color: var(--primary-color);
}

header.default-header .sub-header .social-icone ul li {
    border: none;
    background: transparent;
}

header.default-header .sub-header .social-icone ul li i {
    width: 30px;
}

header.default-header .sub-header .number-info li {
    border: none;
}

header.default-header .main-header,
header.default-header .sub-header {
    padding: 0 40px;
}

header.default-header .navbar-light .navbar-toggler-icon {
    background-image: none;
    position: relative;
    width: 34px;
    height: 34px;
}

header.default-header .navbar-light .navbar-toggler-icon i {
    line-height: 0;
    font-size: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

@media(min-width:2200px) {

    .elementor-9 .elementor-element.elementor-element-1298d214:not(.elementor-motion-effects-element-type-background),
    .elementor-7 .elementor-element.elementor-element-5f32e27:not(.elementor-motion-effects-element-type-background) {
        background-size: contain!important;
    }

    header.default-header .main-header, header.default-header .sub-header {
        padding: 0 40px;
    }
}

@media(max-width:1920px) {
    header.default-header .main-header, header.default-header .sub-header {
        padding: 0 40px;
    }
}

@media(max-width:1499px) {
    header.default-header .navbar ul li {
        margin-right: 5px;
    }
}

@media(max-width:1399px) {
    header.default-header .sub-header {
        padding: 10px 30px
    }

    header.default-header .main-header, header.default-header .sub-header {
        padding: 0 45px;
    }

    header.default-header .navbar ul li {
        margin-right: 5px;
    }

    header.default-header .sub-main{
        margin-left: 25px;
    }
}

@media(max-width:1365px) {
    header.default-header .sub-header {
        padding: 0 15px
    }

    header.default-header .main-header, header.default-header .sub-header {
        padding: 0 15px;
    }
}


@media(max-width:1199px) {
    header.default-header.menu-sticky .main-header .navbar ul li a {
        line-height: normal;
    }

    header.default-header.menu-sticky .main-header .navbar ul li:hover .sub-menu,
    header.default-header.menu-sticky .navbar ul li .sub-menu li:hover>.sub-menu,
    header.default-header.default-header .navbar ul li .sub-menu li:hover>.sub-menu {
        top: 0
    }

    header.default-header .navbar ul li .sub-menu li {
        padding: 0;
    }

    header.default-header .navbar ul.sub-menu li {
        padding: 0;
    }

    header.default-header .main-header, header.default-header .sub-header {
        padding: 0 15px;
    }

    header.default-header .navbar ul li {
        margin-right: 10px;
    }

    header.default-header .iq-button-style-2, header .iq-button-style-1 {
        display: none;
    }

    header.default-header .iq-mobile-main {
        display: block;
    }

    header.default-header .iq-mobile-main .iq-button-style-2,
    header.default-header .iq-mobile-main .iq-button-style-1 {
        display: inline-block;
    }

    header.default-header .iq-mobile-main {
        background-color: var(--white-color);
        padding: 15px;
    }

    header.default-header .navbar-light {
        align-items: center;
    }

    header.default-header .navbar ul li .sub-menu,
    header.default-header .navbar ul li:hover .sub-menu {
        display: none;
        background: rgba(125, 144, 166, 0.14);
        position: relative;
        top: 0;
        width: 100%;
        padding: 10px;
        z-index: 9;
    }

    header.default-header .navbar ul li .sub-menu li:hover>.sub-menu {
        width: 100%;
        background: rgba(125, 144, 166, 0.14);
        position: relative;
        left: 0;
        box-shadow: none;
        padding: 10px;
    }

    header.default-header .navbar ul.navbar-nav {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 350px;
        float: left;
        display: inline-block;
        width: 100%;
        background: var(--white-color);
    }

    header.default-header .navbar ul li:hover .sub-menu {
        box-shadow: none;
    }

    header.default-header .main-header,
    header.default-header .sub-header {
        padding: 10px 15px;
    }

    header.default-header.style-two ul li .sub-menu,
    header.default-header.style-two .navbar ul li:hover .sub-menu {
        background: var(--body-text);
    }

    header.menu-sticky .main-header {
        padding: 10px 15px;
    }

    header.default-header .navbar .navbar-nav li .sub-menu li a,
    header.default-header .navbar ul li .sub-menu li .sub-menu li.menu-item a {
        background: var(--white-color);
    }

    header.default-header .navbar ul li .sub-menu li:hover>.sub-menu,
    header.default-header .navbar .navbar-nav li .sub-menu li:hover>.sub-menu {
        padding: 10px;
    }

    header.default-header.default-header .main-header {
        z-index: 99;
    }

    header.default-header .navbar .sub-main .blue-btn.button {
        right: 70px;
        position: relative;
    }

    header.default-header .navbar-light .navbar-toggler {
        background: var(--primary-color);
        color: var(--white-color);
        border-color: var(--primary-color);
        padding: .25rem .5rem;
        position: absolute;
        right: 0;
    }

    header.default-header .navbar-light .navbar-toggler {
        right: 0;
        border-radius: 0;
        padding: 5px;
    }

    header.default-header .navbar-light .navbar-toggler:focus {
        outline: none
    }

    header.default-header .navbar-collapse {
        position: absolute;
        top: 90px;
        line-height: 0;
        left: 0;
        width: 100%;
        -webkit-box-shadow: 0 10px 30px 0 rgb(23 28 37 / 0.1);
        -moz-box-shadow: 0 10px 30px 0 rgb(23 28 37 / 0.1);
        box-shadow: 0 10px 30px 0 rgb(23 28 37 / 0.1)
    }

    header.menu-sticky .navbar-collapse {
        top: 68px;
    }

    header.default-header .navbar ul {
        float: left;
        display: inline-block;
        width: 100%;
        background: var(--white-color);
    }

    header.default-header .navbar ul li.current-menu-parent>a {
        background: var(--primary-color);
        color: var(--white-color);
    }

    header.default-header .navbar ul li, header.default-header .navbar ul.sub-menu li {
        margin-right: 0;
        display: inline-block;
        position: relative;
        width: 100%;
    }

    header.default-header .navbar ul li a {
        color: var(--title-color);
        line-height: normal;
        display: inline-block;
        width: 100%;
        font-size: var(--font-size-18);
        padding: 15px;
        z-index: inherit;
        text-align: left;
    }

    header.default-header .navbar ul li a:before {
        border-radius: 0
    }

    header.default-header .navbar ul li .sub-menu li a {
        color: var(--body-text);
        padding: 15px;
    }

    header.default-header .navbar ul li i {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        padding: 12px 15px;
        line-height: 28px;
        font-size: var(--font-size-20);
        z-index: 9;
        color: var(--title-color);
        transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -ms-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out
    }

    header.default-header .navbar ul li.current-menu-item i,
    header.default-header .navbar ul li.current-menu-parent i {
        color: var(--white-color);
    }

    header.menu-sticky .main-header.default-header .navbar ul li a {
        line-height: normal;
        text-align: left;
    }

    header.default-header .navbar ul li .sub-menu li i {
        top: 0;
        right: 0;
        color: var(--secondary-color);
        font-size: 20px;
        padding: 8px 15px;
        transform: inherit;
    }

    header.default-header .navbar ul li .sub-menu li:hover>.sub-menu {
        width: 100%;
        background: var(--white-light-color);
        position: relative;
        left: 0;
        box-shadow: none;
        padding: 0;
    }

    header.default-header .navbar .navbar-nav li:last-child .sub-menu li:hover>.sub-menu,
    header.default-header .navbar .navbar-nav li:nth-last-child(2) .sub-menu li:hover>.sub-menu {
        right: 0;
    }

    header.default-header .navbar ul li.current-menu-item a, header.default-header .navbar ul li a:hover {
        background: var(--primary-color);
        text-align: left;
        color: var(--white-color);
    }

    header.default-header .navbar-brand {
        line-height: 0;
    }

    header.default-header .shop_list {
        background: none!important;
        position: absolute;
        right: 0;
        top: 0;
    }

    header.default-header .blue-btn.button {
        position: inherit;
        padding: 6px 20px;
    }
    

    #shop-toggle {
        right: 60px;
    }
}

@media(max-width:1299px) {

    header.default-header .navbar ul li {
        margin-right: 5px;
        font-size: var(--font-size-15);
    }

    header.default-header .sub-main{
        margin-left: 16px;
    }
}

@media screen and (max-width:1250px) and (min-width:1200px) {
    header.default-header .navbar-light .navbar-brand img.logo {
        width: 150px;
    }
}

@media only screen and (device-width:1024px) {
    header.default-header .navbar ul li {
        margin-right: 20px;
    }

    header.default-header .navbar ul li {
        margin-right: 10px;
    }

    header.default-header .main-header, header.default-header .sub-header {
        padding: 0 15px;
    }
}

@media(max-width:1024px) {
    header.default-header .main-header, header.default-header .sub-header {
        padding: 0 15px;
    }
}

@media(max-width:767px) {

    header.default-header .sub-header .request-btn a {
        padding: 14px 30px;
        margin-left: 0;
        right: -15px;
        top: 2px;
    }

    header.default-header .sub-header .request-btn a:before {
        width: 126%;
    }

    header.default-header .sub-header .social-icone {
        margin-left: 0;
    }

    header.default-header .sub-header .number-info li,
    header.default-header .sub-header .number-info li:last-child {
        border: none;
    }

    header.default-header .container-fluid.sub-header .row .col-auto.mr-auto {
        background: var(--white-light-color);
    }

    header.default-header .sub-header .number-info li:last-child {
        text-align: right;
    }


    header.default-header .sub-header .number-info li a {
        font-size: var(--font-size-xs);
    }

    header.default-header .navbar-light .navbar-toggler {
        right: 0;
    }

    header.default-header .navbar .sub-main .blue-btn.button {
        position: relative;
    }
}

@media(max-width:527px) {
    .sub-header .social-icone {
        display: none;
    }

}

@media(max-width:479px) {
    header.default-header .navbar-light .navbar-toggler {
        right: 0;
    }

    .iq-breadcrumb-one .breadcrumb li:last-child::after {
        display: none;
    }

    header.default-header .sub-header .number-info li a {
        font-size: var(--font-size-xs);
    }

    header.default-header .sub-header .request-btn a:before {
        width: 260%;
        left: -15px;
    }

    header.default-header .navbar .sub-main .blue-btn.button {
        display: none;
    }
}

@media(max-width:359px) {
    .sub-header {
        display: none;
    }
}

@media(max-width:324px) {
    header.default-header .iq-button-style-1 {
        margin-top: 15px;
    }
}