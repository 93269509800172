/**
 * Custom Properties
 * aka CSS variables.
 *
 * @link: https://developer.mozilla.org/en-US/docs/Web/CSS/--*
 **/

 :root {

	--primary-color: #7093e5;
    --secondary-color: #f68685;
    --secondary-text: #f46462;
    --light-pink-color: #fef5f5;
    --white-color: #fff;
    --white-light-color: #ecf2ff;
    --title-color: #171c26;
    --sub-title-color: #7093e5;
    --body-text: #6e7990;
    --light-blue-color: #ecf2ff;
    --ver-menu-back: rgba(67, 126, 235, 0.08);
    --warning-color: #ff0000;
    --success-color: #398f14;

	/* Container */
	--content-width: 87.5em;
	--content-width-sm: 73.125em;

	/* Typography */
	--global-font-family: 'Roboto', sans-serif; 
	--highlight-font-family: 'Heebo', sans-serif;
	--global-font-size:var(--font-size-body);
	--global-font-line-height: 1.4;
	--global-box-shadow: 0 1px 30px 0 rgb(36 38 43 / 10%);
	--sider-bar-shadow: 7px 0 18px 0 rgb(0 0 0 / 8%);

	/* Custom editor font sizes */
	--font-size-small: calc(16 / var(--global-font-size) * 1em);
	--font-size-regular: calc(var(--global-font-size) / 16 * 1em);
	--font-size-large: calc(36 / var(--global-font-size) * 1em);
	--font-size-larger: calc(48 / var(--global-font-size) * 1em);

	/* Fontsize */
	--font-size-small: 0.75rem;
	--font-size-normal: 0.875rem;
	--font-size-body: 1rem;
	--font-size-h1: 4.209rem;
	--font-size-h2: 3.157rem;
	--font-size-h3: 2.369rem;
	--font-size-h4: 1.777rem;
	--font-size-h5: 1.333rem;
	--font-size-h6: 1rem;
	--font-size-sm: 0.875rem;   /*(14px)*/
	--font-size-xs: 0.75rem;   /*(12px)*/

	/* Line-Height */
	--font-line-height-body: 1.75;
	--font-line-height-p: 1.75;
	--font-line-height-h1: 1.3;
	--font-line-height-h2: 1.3;
	--font-line-height-h3: 1.3;
	--font-line-height-h4: 1.3;
	--font-line-height-h5: 1.3;
	--font-line-height-h6: 1.75;

	/* Font-Weight */
	--font-weight-body: 500;
	--font-weight-h1: 500;
	--font-weight-h2: 500;
	--font-weight-h3: 500;
	--font-weight-h4: 500;
	--font-weight-h5: 500;
	--font-weight-h6: 500;

	/* font-weight*/
	--font-weight-light:300;
	--font-weight-regular:400;
	--font-weight-medium:500;
	--font-weight-semi-bold:600;
	--font-weight-bold:700;

	/* Letter spacing */
	--font-letter-spacing-body: 0;
	--font-letter-spacing-link:0.094rem;
	--font-letter-spacing-h1: 0;
	--font-letter-spacing-h2: 0;
	--font-letter-spacing-h3: 0;
	--font-letter-spacing-h4: 0;
	--font-letter-spacing-h5: 0;
	--font-letter-spacing-h6: 0;
	--letter-spacing:0.25em;
	--letter-spacing-one: 0.063em;
	--letter-spacing-two: 0.125em;
    --letter-spacing-three: 0.188em;
	--letter-spacing-four: 0.25em;

	/* border-radius */
	--border-radius: 0;
	--border-radius-box: 0;
	--border-radius-ten:0;
	--border-radius-line:1em;
	--border-radius-50: 50px;
	
	/* z index */
	--z-index-minus:-1;

    /* Colors Add */
    --global-font-title: #171c26;
    --global-font-color: #6e7990;
	--border-color-light: #dbdfe7;
	--border-color-dark: #171c26;
	--border-color-white-light: #eaeaea;
	--lab-border-color: #d8dddd;

	/* Custom editor colors */
	--color-theme-grey: #f5f7fb;
	--color-theme-dark: #333;
	--color-theme-black: #000;
	--color-theme-orange: #ff8d39;
	--color-theme-yellow: #f9a620;
	--color-theme-blue: #21759b;
	--woocommerce-border-color: #ededed;
	--woocommerce-grey: #666;
	--color-theme-primary-green:#247b7b;
	--color-theme-secondary-orange:#f2c078;
	--color-theme-orange-light: #faf6f6;
	--color-theme-service-icon: #111a31;
	--color-theme-blue-light: #f5fbff;
	--color-theme-green-light: #f0f6f6;


    /* Custom - Fontsize */
	--font-size-13: 13px;
	--font-size-15: 15px;
	--font-size-18: 18px;
	--font-size-20: 20px;
	--font-size-22: 22px;
	--font-size-24: 24px;
	--font-size-26: 26px;
	--font-size-30: 30px;
	--font-size-32: 32px;
	--font-size-36: 36px;
	--font-size-42: 42px;
	--font-size-45: 45px;
	--font-size-50: 50px;
	--font-size-62: 62px;
	--font-size-90: 90px;
	--font-size-150: 150px;
	--font-size-240: 240px;
}


@media (max-width: 991px){
    :root {
        --font-size-h1: 3.653em;
        --font-size-h2: 2em;
        --font-size-h3: 1.827em;
		--font-size-h4: 1.700em;
    }
}



	