/* ==========old Css===== */

/*---------------------------------------------------------------------
 Footer
-----------------------------------------------------------------------*/

.recentcomments a {
    display: inline!important;
    padding: 0!important;
    margin: 0!important;
}

footer table td,
footer table th {
    border-color: rgba(36, 38, 43, 0.14);
}

footer .widget {
    background: transparent;
    border: none;
    padding: 0;
    box-shadow: none;
}

footer .widget ul li {
    border: none;
    padding: .5em 0;
    margin-bottom: 0;
}

footer .widget ul.menu li {
    padding: 0;
}

footer .widget ul.wp-tag-cloud li {
    padding: 0;
    margin: 5px 5px 5px 0;
}

footer .widget .iq-contact li a:before {
    display: none;
}

footer .widget #recentcomments li a {
    color: var(--primary-color);
}

footer .widget #recentcomments li .comment-author-link a:hover {
    text-decoration: underline;
    color: var(--primary-color);
}

footer .widget.widget_nav_menu ul li a:before,
footer .widget ul.menu li a:before {
    top: 16px;
}

footer .widget.widget_nav_menu ul li a {
    padding: 7px 0 7px 15px;
}

footer .widget ul.menu li .sub-menu {
    padding-left: 10px;
}

ul.iq-contact li {
    display: flex;
}

ul.iq-contact li a span {
    padding-left: 40px;
    display: table-cell;
    word-wrap: anywhere;
    word-break: break-word;
}

[dir="rtl"] ul.iq-contact li a span{
    padding-left: 0;
    padding-right: 40px;
}

ul.iq-contact li i {
    position: absolute;
    left: 0;
    line-height: 34px;
    font-size: var(--font-size-22);
    color: var(--primary-color);
    top: -2px;
}

ul.iq-contact li svg {
    position: absolute;
    left: 0;
    font-size: var(--font-size-22);
    height: 25px;
    width: 25px;
}

ul.iq-contact li svg path {
    fill: var(--primary-color);
}

ul.iq-contact li a:before {
    display: none;
}

.widget.widget_nav_menu ul li .sub-menu li a,
.widget ul.menu li .sub-menu li a {
    font-size: var(--font-size-sm);
}

footer.footer-one .widget ul li a.rsswidget {
    padding-left: 0;
    font-size: var(--font-size-18);
    color: var(--title-color);
    font-weight: var(--font-weight-medium);
}

footer .widget .rss-date {
    font-size: var(--font-size-sm);
    text-transform: uppercase;
    display: inline-block;
    width: 100%;
    margin: 5px 0;
    color: var(--primary-color);
}

footer.footer-one .widget .textwidget p {
    margin-bottom: 0;
}

footer .widget.footer-logo.text-left {
    margin-bottom: 15px
}

footer.footer-one {
    display: inline-block;
    width: 100%;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background: var(--light-blue-color);
}

footer.footer-one .footer-top {
    padding-top: 100px;
    padding-bottom: 45px;
}

footer.footer-one .widget {
    background: transparent;
}

footer.footer-one .widget h4.footer-title {
    margin-bottom: 15px;
}

footer .widget #recentcomments li .comment-author-link a {
    color: var(--body-text);
    font-weight: var(--font-weight-semi-bold);
}

footer .widget #recentcomments li.recentcomments a:before,
footer .widget a.rsswidget:before,
footer ul.wp-tag-cloud li a:before {
    display: none;
}

footer.footer-one .widget ul.menu li a {
    border: none;
    position: relative;
}

footer.footer-one .widget ul.menu li a:hover {
    color: var(--primary-color);
}

footer.footer-one .sub-btn {
    position: absolute;
    right: 0;
}

footer.footer-one .sub-btn button {
    padding: 24px 30px;
    border: none;
    cursor: pointer;
    background-color: var(--primary-color);
}

footer.footer-one .sub-btn button:hover,
.footer-one .sub-btn button:focus {
    outline: none;
}

footer.footer-one .sub-btn button:before {
    content: "\f2c3";
    position: absolute;
    font-family: "Ionicons";
    z-index: 1;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-size: var(--font-size-24);
    color: var(--white-color);
}

footer.footer-one input[type=submit] footer.footer-one .info-share li {
    margin-left: 15px;
    margin-right: 0;
}

footer.footer-one .menu-footer-menu-container .menu {
    margin-bottom: 0;
    padding: 0;
}

footer.footer-one .menu-footer-menu-container .menu li {
    list-style: none;
    display: block;
    margin-right: 10px;
}

footer.footer-one .copyright a:hover,
footer.footer-one .menu-footer-menu-container .menu li a:hover,
footer.footer-one .info-share li a:hover {
    text-decoration: none;
    color: var(--secondary-color);
}

footer.footer-one .menu-footer-menu-container .menu li:last-child {
    margin-right: 0;
}

footer.footer-one .copyright-footer {
    border-top: 1px solid rgba(82, 95, 129, 0.09);
    position: relative;
}

footer.footer-one .info-share li a {
    display: block;
    color: var(--white-color);
    position: relative;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-decoration: none;
    background: var(--primary-color);
    font-size: var(--font-size-xs);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}

footer.footer-one .info-share li a:hover {
    background: var(--secondary-color);
    color: var(--white-color);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    transform: translateY(-2px);
}

footer.footer-one input[type=email]:hover,
footer.footer-one input[type=email]:focus {
    border-color: var(--primary-color);
    padding: 15px;
    opacity: 1;
}

footer.footer-one input[type=email] {
    background: transparent;
    border-color: var(--body-text);
    opacity: .4;
}

.mc4wp-form-fields input[type="submit"] {
    position: absolute;
    right: 15px;
}

footer.footer-one .footer-top .info-share li {
    margin-right: 15px;
    display: inline-block;
    float: none;
    vertical-align: middle;
    text-align: center;
    list-style: none;
}

footer.footer-one .footer-top .info-share li:last-child {
    margin-right: 0;
}

.iq-bg-dark {
    position: relative;
}

footer.iq-over-dark-90:before {
    display: none!important;
}

footer .footer_top_block {
    padding-bottom: 100px;
}

footer .mc4wp-form-fields {
    float: left;
    width: 100%;
    position: relative;
}

footer .footer-top .mc4wp-form input,
footer .footer-top .mc4wp-form input[type=email]:focus {
    border: 1px solid var(--primary-color);
    padding: 15px 70px 15px 15px;
    background: transparent;
}

footer .footer-top .mc4wp-form button {
    font-size: 0;
    position: absolute;
    right: 0;
    top: 0;
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    outline: none;
}

footer .footer-top .mc4wp-form button i {
    font-size: var(--font-size-20);
    color: var(--white-color);
    width: 55px;
    height: 55px;
    line-height: 55px;
    background: var(--primary-color);
    margin-right: 0;
}

.widget ul li a,
ul.wp-block-archives-list li a {
    position: relative;
    display: inline-block;
}

.elementor-icon-box-icon {
    line-height: 0;
}

footer .footer-top .mc4wp-form input::placeholder {
    color: var(--global-font-title);
}


/* new css add */
footer .widget.widget_nav_menu ul li a,
footer .widget ul.menu li a {
    padding: 10px 0;
}


@media screen and (min-width: 1200px) {
    footer.footer-one .widget .mc4wp-form .mc4wp-form-fields {
        width: 70%;
    }
}