@import "_custom-properties.css";

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
/* stylelint-disable */
html {
    box-sizing: border-box;
}

/* stylelint-enable */

/**
 * Inherit box-sizing to make it easier to change the property for
 * components that leverage other behavior; see
 * http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
    box-sizing: inherit;
}

a:focus {
    color: var(--primary-color);
    text-decoration: none !important;
}

a:hover {
    color: var(--primary-color);
    text-decoration: none;
}

:focus {
    outline: none;
}

a {
    outline: none;
}

p {
    margin: 1em 0;
    line-height: 1.66em;
}

/* stylelint-disable */
hr {
    background-color: var(--white-light-color);
    border: 0;
    height: .063em;
    margin: 0;
    padding: 0;
}

/* stylelint-enable */

ul,
ol {
    padding-left: 1.563em;
    margin-bottom: 1em;
}


ol ol {
    padding-left: 1.563em;
}


/* Definition Lists */
dd {
    margin: 0 1.5em 1.5em;
}

dl dd {
    margin-bottom: 1em;
}

dl dd:last-child {
    margin-bottom: 0;
}

li>ul,
li>ol {
    margin-bottom: 0;
}

dt {
    font-weight: var(--font-weight-bold);
}

/* stylelint-disable */
img {
    max-width: 100%;
    height: auto;
}

/* stylelint-enable */


/* Table */
table {
    border: 0.063em solid var(--border-color-light);
    width: 100%;
    margin-bottom: 1.25em;
}

table td,
table th {
    border: 0.063em solid var(--border-color-light);
    padding: .313em .5em;
    text-align: center;
}

/* inputs */

input[type="checkbox"] {
    width: auto;
    margin-right: .625em;
    line-height: 2;
    height: 32px;
}

input,
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
    width: 100%;
    float: left;
    padding: 0 15px;
    height: 54px;
    line-height: 54px;
    color: var(--title-color);
    background: var(--white-color);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
    -webkit-border-radius: var(--border-radius);
    -moz-border-radius: var(--border-radius);
    -ms-border-radius: var(--border-radius);
    -o-border-radius: var(--border-radius);
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
}

select {
    border: 0.063em solid var(--white-color);
    background: var(--global-body-bgcolor);
    line-height: 3.75em;
    height: 3.75em;
    padding: 0 .625em;
    width: 100%;
    color: var(--global-font-color);
    border-radius: var(--border-radius);
    -webkit-border-radius: var(--border-radius);
    -moz-border-radius: var(--border-radius);
    -ms-border-radius: var(--border-radius);
    -o-border-radius: var(--border-radius);
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
}

textarea {
    padding: 1em;
    height: 9.375em;
    line-height: var(--font-line-height-body);
}

input::-webkit-input-placeholder {
    color: var(--body-text);
}

input::-moz-placeholder {
    color: var(--body-text);
}

input:-ms-input-placeholder {
    color: var(--body-text);
}

textarea::-webkit-input-placeholder {
    color: var(--body-text);
}

textarea::-moz-placeholder {
    color: var(--body-text);
}

textarea:-ms-input-placeholder {
    color: var(--body-text);
}

input[type="email"]::-webkit-input-placeholder {
    color: var(--body-text);
}

input[type="email"]::-moz-placeholder {
    color: var(--body-text);
}

input[type="email"]:-ms-input-placeholder {
    color: var(--body-text);
}

input[type="text"]:focus,
input:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus,
select:focus {
    border-color: var(--primary-color);
    box-shadow: none;
    outline: none;
}

embed,
iframe,
object {
    max-width: 100%;
    width: 100%;
}

iframe {
    border: none;
}




/* ============Old CSS=========== */

a {
    color: var(--sub-title-color);
    outline: none;
}

a:focus,
a:hover {
    color: var(--secondary-color);
    outline: none;
}

ins {
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
}

pre {
    background: var(--white-light-color);
    padding: 15px;
    border: 1px solid var(--white-light-color);
}

hr {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid var(--white-light-color);
    border-top: 0;
}


/* Lists (Nested) */

ol,
ul {
    padding-left: 25px;
    margin-bottom: 1em;
}

ol li {
    list-style: decimal;
}

ol ol {
    padding-left: 25px;
}

ul li {
    list-style: inherit;
}


/* Definition Lists */

dl dd {
    margin-bottom: 15px;
}

dl dd:last-child {
    margin-bottom: 0;
}


/* Table */

table {
    border: 1px solid var(--white-light-color);
    width: 100%;
    margin-bottom: 10px;
}

table td,
table th {
    border: 1px solid var(--white-light-color);
    padding: 5px 8px;
    text-align: center;
}

label {
    color: var(--body-text);
    margin-bottom: 0;
}

/* Form */

input,
input[type=text],
input[type=email],
input[type=search],
input[type=password],
textarea {
    width: 100%;
    padding: 0 15px;
    height: 54px;
    line-height: 54px;
    color: var(--title-color);
    background: var(--white-color);
    border: 1px solid var(--border-color-light);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
}

select {
    border: 1px solid rgba(36, 38, 43, 0.14);
    background: var(--white-color);
    line-height: 48px;
    height: 48px;
    padding: 0 10px;
    width: 100%;
    color: var(--title-color);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
}

textarea {
    padding: 15px;
    min-height: 9.375em;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    line-height: inherit;
}

input:focus,
input[type=text]:focus,
input[type=email]:focus,
input[type=search]:focus,
input[type=password]:focus,
textarea:focus,
select:focus,
input[type=tel]:focus {
    border-color: var(--primary-color);
    box-shadow: none;
    outline: none;
}

input::-webkit-input-placeholder {
    color: var(--body-text);
}

input::-moz-placeholder {
    color: var(--body-text);
}

input:-ms-input-placeholder {
    color: var(--body-text);
}

textarea::-webkit-input-placeholder {
    color: var(--body-text);
}

textarea::-moz-placeholder {
    color: var(--body-text);
}

textarea:-ms-input-placeholder {
    color: var(--body-text);
}

input[type=email]::-webkit-input-placeholder {
    color: var(--body-text);
}

input[type=email]::-moz-placeholder {
    color: var(--body-text);
}

input[type=email]:-ms-input-placeholder {
    color: var(--body-text);
}

input[type=submit],
.contact-form .cfield input[type=submit] {
    color: var(--white-color);
    background: var(--primary-color);
    margin-bottom: 0;
    height: auto;
    line-height: 26px!important;
    padding: 11px 30px;
    font-size: var(--font-size-body);
    border: none;
    position: relative;
    display: inline-block;
    width: auto;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    cursor: pointer;
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
}

input[type=submit]:hover,
.contact-form .cfield input[type=submit]:hover {
    color: var(--white-color);
    background: var(--secondary-color);
    outline: none;
}

embed,
iframe,
object {
    max-width: 100%;
    width: 100%;
}