@import "_custom-properties.css";

/* ==========old Css===== */

/*---------------------------------------------------------------------
                               Loader
-----------------------------------------------------------------------*/
#loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--white-color);
    z-index: 9999;
}

#loading img {
    width: 400px;
}


/*---------------------------------------------------------------------
 404
-----------------------------------------------------------------------*/
.error-bg {
    float: left;
    width: 100%;
    display: block;
}

.error-404 .big-text {
    font-size: var(--font-size-240);
    font-family: var(--highlight-font-family);
    line-height: 240px;
    color: var(--secondary-color);
    font-weight: var(--font-weight-bold);
}

.kivicare-gradient-bg-top {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #eff1fe), color-stop(60%, #ffffff));
    background: -moz-linear-gradient(top, #eff1fe 0, #ffffff 60%);
    background: -webkit-linear-gradient(top, #eff1fe 0, #ffffff 60%);
    background: -o-linear-gradient(top, #eff1fe 0, #ffffff 60%);
    background: -ms-linear-gradient(top, #eff1fe 0, #ffffff 60%);
    background: linear-gradient(top, #eff1fe 0, #ffffff 60%);
}

.kivicare-gradient-bg-bottom {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #eff1fe), color-stop(60%, #ffffff));
    background: -moz-linear-gradient(bottom, #eff1fe 0, #ffffff 60%);
    background: -webkit-linear-gradient(bottom, #eff1fe 0, #ffffff 60%);
    background: -o-linear-gradient(bottom, #eff1fe 0, #ffffff 60%);
    background: -ms-linear-gradient(bottom, #eff1fe 0, #ffffff 60%);
    background: linear-gradient(bottom, #eff1fe 0, #ffffff 60%);
}

ul.iq-list {
    margin: 0;
}

.column-2 ul.iq-list {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}

@media(max-width:479px) {
    .error-404 .big-text {
        font-size: var(--font-size-150);
        line-height: 160px
    }
}

@media(max-width:359px) {
    .error-404 h1 {
        font-size: var(--font-size-62);
    }
}

/*---------------------------------------------------------------------
                              Back to Top
-----------------------------------------------------------------------*/
#back-to-top .top {
    margin: 0;
    color: var(--white-color);
    background: var(--secondary-color);
    position: fixed;
    bottom: 30px;
    right: 31px;
    z-index: 999;
    font-size: var(--font-size-26);
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 0;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

#back-to-top .top:hover {
    background: var(--primary-color);
    color: var(--white-color);
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}


/*---------------------------------------------------------------------
 Breadcrumb style 1 2 and 3 4 5
-----------------------------------------------------------------------*/

.breadcrumb li {
    display: inline-block;
    word-break: break-all;
}

.iq-breadcrumb {
    padding: 100px 0;
    background: var(--light-blue-color);
}

.wp-block-cover {
    margin-bottom: 30px;
}

.iq-breadcrumb-two {
    padding: 45px 0;
    position: relative;
    z-index: 2;
}

.iq-breadcrumb-one {
    display: block;
    padding: 60px 0;
    position: relative;
    background-repeat: no-repeat!important;
    background-position: center center!important;
    background-size: cover;
    background-color: var(--light-blue-color);
}

.iq-breadcrumb-two h2 {
    margin: 0;
}

.iq-breadcrumb-one ol li a {
    color: var(--primary-color);
    text-decoration: none;
}

.iq-breadcrumb-one ol li a:hover {
    color: var(--secondary-color);
}

.iq-breadcrumb-one ol li {
    list-style: none;
    display: inline-block;
}

.iq-breadcrumb-one .breadcrumb-item+.breadcrumb-item::before {
    content: "\f3d3";
    font-family: "Ionicons";
    color: var(--primary-color);
}

.iq-breadcrumb-one .breadcrumb {
    position: relative;
    display: inline-block;
}

.iq-breadcrumb-one .breadcrumb-item.active {
    color: var(--primary-color);
    position: relative;
}

.iq-breadcrumb-one ol {
    background: transparent;
    padding: 0;
    margin-bottom: 0;
    margin-top: 15px;
}

.iq-breadcrumb-two .breadcrumb {
    display: inline-block;
}

.iq-breadcrumb-two ol li {
    display: inline-block;
}

.iq-breadcrumb-three .breadcrumb {
    margin-top: 0;
}

.iq-breadcrumb-three .iq-breadcrumb-one {
    padding: 0;
}

.iq-breadcrumb-three {
    padding: 45px 0;
}

.breadcrumb-bg,
.breadcrumb-video,
.video-iq-bg-over {
    position: relative;
    overflow: hidden;
}

.breadcrumb-bg video,
.breadcrumb-bg #video-background,
.breadcrumb-video video,
.video-iq-bg-over video,
.video-breadcrumb-bg #video-background,
.video-iq-bg-over #video-background {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 100%;
}

.breadcrumb-bg.iq-over-dark-80:before,
.breadcrumb-ui:before {
    z-index: 0;
}

/*---------------------------------------------------------------------
                          Background Overlay Color
-----------------------------------------------------------------------*/

.iq-bg-over {
    background: var(--white-light-color);
}

.breadcrumb-bg {
    position: relative;
}

.breadcrumb-bg:before,
.breadcrumb-ui:before,
.breadcrumb-video::before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    background: rgba(2, 13, 30, 0.9);
    display: block!important;
}

.breadcrumb-video::before {
    z-index: 1;
}

.button-link {
    color: var(--primary-color);
    font-weight: var(--font-weight-bold);
}

.button-link:hover {
    color: var(--primary-color);
    text-decoration: none;
}

.button-link i {
    margin-left: 5px;
}

.button_link {
    color: var(--secondary-color);
    font-weight: var(--font-weight-bold);
    font-family: var(--highlight-font-family);
}

.button_link i {
    margin-right: 10px;
    color: var(--primary-color);
}

.button_link:hover {
    color: var(--secondary-color);
}

.fancy_banner {
    padding: 0 150px;
}
