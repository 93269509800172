/*SideBar - Search*/

.search-form {
  position: relative;
}

.search-form label {
  display: none;
}

.search-form input {
  padding-right: 40px;
  box-shadow: none;
  color: var(--body-text);
  border: 1px solid rgba(36, 38, 43, 0.14);
  background: var(--white-color);
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  border-radius: 0;
}

.search-form input:focus,
.search-form input[type=text]:focus,
.search-form input[type=email]:focus,
.search-form input[type=search]:focus,
.search-form input[type=password]:focus {
  border: 1px solid var(--primary-color);
}

.search-form .search-submit,
.widget.widget_search .wp-block-search .wp-block-search__inside-wrapper .wp-block-search__button {
  position: absolute;
  right: 0;
  top: 0;
  color: var(--white-color);
  cursor: pointer;
  padding: 0;
  font-size: var(--font-size-18);
  background: var(--primary-color);
  border: none;
  outline: none;
  box-shadow: none;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  width: 54px;
  height: 54px;
  line-height: 54px;
}

.search-form .search-submit:hover {
  background: var(--secondary-color);
}

.search-form .search-submit .screen-reader-text {
  display: none;
}

.wp-block-search__inside-wrapper {
  position: relative;
}

.widget.widget_search .wp-block-search .wp-block-search__inside-wrapper .wp-block-search__button {
  font-size: 0;
}

.widget.widget_search .wp-block-search .wp-block-search__inside-wrapper .wp-block-search__button:before {
  content: "\f002";
  font-family: 'Font Awesome 5 Free';
  font-size: var(--font-size-18);
  font-weight: 900;
}


/*********Mordern Button Style**********/

.search-box input[type="search"] {
  height: 2.5em;
  font-size: var(--font-size-18);
  display: inline-block;
  border: none;
  border-radius: .312em;
  outline: none;
  padding: 1em 2.5em 1em 1em;
  width: 0;
  position: absolute;
  top: -.56em;
  right: 0;
  background: none;
  transition: width .4s cubic-bezier(0, 0.795, 0, 1);
  cursor: pointer;
  z-index: 11;
  margin: -.625em 0 0;
}

.search-form .search-submit {
  position: absolute;
  right: 0;
  top: 0;
  width: 58px;
  height: 58px;
  line-height: 58px;
  color: var(--white-color);
  cursor: pointer;
  font-size: var(--font-size-body);
  background: var(--primary-color);
  border: none;
  outline: none;
  box-shadow: none;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  border-radius: 0;
}

.search-box input[type="search"]:focus {
  width: 15.62em;
  cursor: text;
  background: var(--white-color);
  z-index: 9;
  color: rgba(102, 102, 102, 0.6);
}

.search-box {
  position: relative;
}

.search-box .search-submit:hover {
  background: none;
}

.search-form .search-submit svg {
  font-size: var(--font-size-18);
  color: var(--white-color);
}

.search-form input:focus,
.search-form input[type=text]:focus,
.search-form input[type=email]:focus,
.search-form input[type=search]:focus,
.search-form input[type=password]:focus {
  border-bottom: .0625em solid var(--primary-color);
}

button.btn-search-close svg {
  color: var(--white-color);
}


.search-form .search-submit svg {
  font-size: var(--font-size-18);
  color: var(--white-color);
}

.navbar ul.css_prefix-shop-btn-holder ul li a:hover {
  background: transparent;
}



header .css_prefix-search,
.search_count .css_prefix-search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  z-index: 1000;
  top: 30px;
  right: 0;
  width: auto;
  pointer-events: none;
  opacity: 0;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -o-opacity: 0;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -o-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out;
}

.css_prefix-search .search-form .form-search .search-submit {
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;
  padding: 0;
  font-size: var(--font-size-18);
  border: none;
  outline: none;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  margin: 0;
  border-radius: 0;
}

.css_prefix-search .search-form .form-search .search-submit:hover {
  background: var(--secondary-color);
}

.css_prefix-search .search-form .form-search .search__input {
  padding-right: 4.25em;
}

.css_prefix-search.search--open,
.search_count .css_prefix-search.search--open {
  pointer-events: auto;
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -o-opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
}

.btn--search-close {
  font-size: var(--font-size-24);
  position: absolute;
  top: 1em;
  right: 1.25em;
  color: var(--white-color);
  background: transparent;
  display: block;
}

.btn--search-close:hover {
  color: var(--white-color);
}

body.admin-bar .btn--search-close {
  top: 1.5em;
}

.btn--search-close:focus {
  outline: 0;
  box-shadow: none;
}

.search-form.search__form {
  width: auto;
}

.search--open .search__form {
  position: relative;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: .7s;
  animation-duration: .7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: visible;
  margin: 0;
}

.search--open .search__form::after {
  -webkit-animation-name: fadeInMove;
  animation-name: fadeInMove;
  -webkit-animation-duration: .7s;
  animation-duration: .7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: visible;
}

header .search__input {
  margin: .625em 0;
  font-family: inherit;
  height: auto;
  font-size: var(--global-font-size);
  line-height: 1;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding: .05em 0;
  color: var(--global-font-color);
}

.form-search input::-webkit-input-placeholder {
  font-size: var(--global-font-size);
}

.search__input::-webkit-input-placeholder,
.search__input::-moz-placeholder,
.search__input:-ms-input-placeholder,
footer .search-form .search-field.search__input::-webkit-input-placeholder,
footer .search-form .search-field.search__input::-moz-placeholder,
footer .search-form .search-field.search__input:-ms-input-placeholder {
  color: var(--global-font-color);
  font-family: var(--highlight-font-family);
  font-size: var(--global-font-size);
  font-weight: 400;
}

.search__input::-webkit-search-cancel-button,
.search__input::-webkit-search-decoration {
  -webkit-appearance: none;
}

.search__input::-ms-clear {
  display: none;
}

form.search-form .form-row .has-content~label,
form.search-form .form-row input:focus~label,
form.search-form .form-row label {
  top: -5.62em;
}

.css_prefix-shop-btn-holder ul li svg {
  font-size: 1.25em;
  height: 1em;
  width: 1.25em;
}

.search-form input {
  height: auto;
  border-radius: 0;
}

.css_prefix-search .search-form input:focus {
  border: 1px solid var(--primary-color);
}

.btn--search-close i {
  color: var(--white-color);
}

.css_prefix-search .search-form .search-submit:hover {
  background-color: var(--primary-color);
}

form.search-form .form-row label {
  color: var(--global-font-color);
  text-align: left;
  font-size: 2.81em;
  top: -.81em;
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 0;
  position: absolute;
  pointer-events: none;
  left: 0;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
}

.search-form input {
  padding-right: 4.25em;
  padding-left: 1em;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 58px;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
}

.vertical-header .search_wrap.search-form-default .search-form input {
  padding-left: 0;
}

.search-form {
  position: relative;
}

header .search-form .form-search .search-submit {
  position: absolute;
  right: .0625em;
  top: .625em;
  cursor: pointer;
  padding: .75em 1em;
  font-size: var(--font-size-18);
  border: none;
  outline: none;
  box-shadow: none!important;
  margin: 0;
}

.wp-block-search .wp-block-search__label {
  display: none;
}

.search-form .form-search .search-submit:hover {
  background-position: right center;
}

.search_count .btn-search,
.search_count .btn-search svg {
  font-weight: var(--font-weight-body);
  font-size: var(--font-size-normal);
  letter-spacing: var(--letter-spacing-three);
  text-transform: uppercase;
}

.search_count .btn-search:hover,
.search_count .btn-search:hover svg {
  color: var(--primary-color);
}

.css_prefix-header-right {
  display: flex;
  align-items: center;
}

/* search-colormode */

.search_style_light .search {
  background: var(--white-color);
  box-shadow: var(--global-box-shadow);
}

.search_style_light .search__form:after {
  background: var(--global-font-color);
}

.search_style_light .search-form .search-submit svg {
  color: var(--global-font-color);
}

.search_style_light input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.search_style_light button.btn-search-close svg {
  color: var(--global-font-title);
}

/* search animation */

.search-animate-left .search {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
}

.search-animate-left .search.search--open {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
}

.search-animate-right .search {
  transform: translateX(-100%);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -o-transform: translateX(100%);
  -ms-transform: translateX(100%);
}

.search-animate-right .search.search--open {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
}

/* Add New CSS */
body.search header.page-header {
  position: static;
  background: transparent;
}

.css_prefix-error .search-form {
  display: inline-block;
  width: 40%;
  margin: 15px 0 25px;
}

.css_prefix-error .page-content>p {
  width: 50%;
  margin: 16px auto;
  color: var(--body-text);
}

.css_prefix-error .search-form input {
  padding: 0 70px 0 16px;
  font-size: var(--font-size-body);
  position: relative;
  background: var(--white-light-color);
  color: var(--global-font-color);
  border: 1px solid var(--white-light-color);
}

.css_prefix-error .search-form .search-submit {
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 768px) {
  .css_prefix-search .search-form.search__form {
    width: 31.25em;
  }
}

@media (max-width: 767px) {
  .css_prefix-search .search-form.search__form {
    width: 15em;
  }
}

.search_count .css_prefix-search .btn-search-close {
  display: none;
}

@media (max-width: 767px) {
  .css_prefix-error .search-form {
    width: 80%;
  }

  .css_prefix-error .page-content>p {
    width: 70%;
  }
}

@media (max-width: 479px) {
  .css_prefix-error .search-form {
    width: 90%;
  }

  .css_prefix-error .page-content>p {
    width: 90%;
  }
}